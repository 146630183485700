import {getNextSequenceNumber, logEvent} from "../../../utils/logger.tsx";
import ky from "ky";
import {getExternalId} from "../../../utils/getExternalId.tsx";
import {getConfig} from "../../../config";

function getCookie(name: string) {
	let cookieValue = null;
	if (document.cookie && document.cookie !== '') {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i]!.trim();
			if (cookie.substring(0, name.length + 1) === (name + '=')) {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookieValue;
}

function create(cookieName: string, type: string) {
	let lock = false;
	const fbpTimer = setInterval(async () => {
		if(lock) return;
		try {
			lock = true;
			const fbp = getCookie(cookieName);
			if(fbp) {
				logEvent(`Найдена кука ${cookieName}, передаём на сервер`);
				await ky.patch('/api/pwa/ffdata', {
					json: {
						type,
						value: fbp,
						cId: getExternalId(),
						rId: getConfig().rId,
						seq: getNextSequenceNumber(),
					}
				});
				clearInterval(fbpTimer);
			}
		} finally {
			lock = false;
		}
	}, 250);
}

export function runResolveFb() {
	create('_fbp', 'fbp');
	create('_fbc', 'fbc');
	create('_ttp', 'ttp');
}
